<template>
  <div class="container">
    <div class="row d-flex new-plan-info-container">
      <div class="col-xxl-8">
        <h2 style="color: var(--partriot-primary-color); font-weight: 900; text-transform: uppercase;">
          HERE ARE YOUR AVAILABLE
          <span v-if="categoryType == 'TERM_LIFE'">Term Life</span>
          <span v-else-if="categoryType == 'MEDICAL'">Major Medical</span>
          <span v-else-if="categoryType == 'GUARANTEED'">Guaranteed Issue Medical</span>
          <span v-else-if="categoryType == 'LIFESTYLE'">Telemedicine</span>
          <span v-else-if="categoryType == 'LIMITEDMED'">Limited Medical Plans</span>
          <span v-else-if="categoryType == 'HSA'">Qualified HSA</span>
          <span v-else>{{ categoryType }}</span>
          PLANS
        </h2>
        <h6 class="label-form mb-3">
          Select a plan below. You can change your coverage and add family
          members.
        </h6>
      </div>
      <div class="col-xxl-4 p-2">
        <h4 style="font-weight: 700">VIEW OTHER PLANS AVAILABLE:</h4>
        <select
            name=""
            class="custom-input"
            :class="{ 'custom-input invalid': groupInvalid }"
            v-model="newCategory"
            id="group_id"
            @change="updateFilter($event.target.value)"
        >
          <option v-for="item in planCategories" :value="item" :key="item">
            <span v-if="item == 'guaranteed'">guaranteed issue medical</span>
            <span v-else-if="item == 'medical'">major medical</span>
            <span v-else-if="item == 'lifestyle'">telemedicine</span>
            <span v-else-if="item == 'limitedmed'">limited medical plans</span>
            <span v-else-if="item == 'hsa'">qualified hsa</span>
            <span v-else-if="item == 'term_life'">term life</span>
            <span v-else>{{ item }}</span>
          </option>
        </select>
      </div>
    </div>
    <div class="network-container d-flex justify-content-end mb-3" v-if="checkMedicalNetwork()">
      <b-form-select
          placeholder="Please Select Medical Network"
          @input="renderNetworkPlan"
          v-model="selectedNetworkPlan"
          :options="medicalPlanNetworkList">
      </b-form-select>
      <div>
        <b-button class="clear-btn"
                  @click="restNetworkFilter"
                  v-b-tooltip.hover title="Clear Medical Network Filter">
          Clear
        </b-button>
      </div>
    </div>
    <div v-if="(categoryType === 'BUNDLES' || categoryType === 'bundles')">
      <div class="bundle-heading">
        <h4  style="font-weight: 700;  text-transform: uppercase;">Multiple Products In One</h4>
      </div>
      <div v-if="bundleCount2.length > 0">
        <bundle-plan :filteredPlans="bundleCount2"
                     @addPlanToCart="addPlan"
                     @routeToViewPlan="routeToViewPlan"
                     :label="'Bundles - Two products in one'" />
      </div>
      <div v-if="bundleCount3.length > 0">
        <bundle-plan :filteredPlans="bundleCount3"
                     @addPlanToCart="addPlan"
                     @routeToViewPlan="routeToViewPlan"
                     :label="'Triple Play - Three products in one'" />
      </div>
      <div v-if="bundleCount4.length > 0">
        <bundle-plan :filteredPlans="bundleCount4"
                     @addPlanToCart="addPlan"
                     @routeToViewPlan="routeToViewPlan"
                     :label="'4 in one - Four products in one'" />
      </div>
      <div v-if="bundleCount5.length > 0">
        <bundle-plan :filteredPlans="bundleCount5"
                     @addPlanToCart="addPlan"
                     @routeToViewPlan="routeToViewPlan"
                     :label="'Ultimate Play Bundle - Five products in one'" />
      </div>
    </div>
    <div v-else>
      <div class="row mb-5" v-if="filteredPlans.length > 0">
        <div
            class="col-xxl-4 col-lg-4 col-md-6 col-sm-12 mb-3"
            v-for="(item, index) in filteredPlans"
            :key="index"
        >
          <div class="new-plan-card call-text-container">
            <call-out-text :callOutText="item.callout_text"/>
            <div class="">
              <div class="img-wrap">
                <img :src="item.plan_logo" />
              </div>
              <h5>{{ item.plan_name }}</h5>
              <ul>
                <p v-html="item.plan_short_text"></p>
              </ul>
            </div>
            <div>
              <div>
                <price-detail-info :priceInfo="item?.tier_price"
                                   :isPlanOnCart="item?.is_in_cart"
                                   @getPlanPricing="addPlan"
                                   :items="item"/>
              </div>
              <div class="plan-button">
                <button class="view-btn" @click="planDetails(item.plan_id)">
                  View More Details
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="text-center mt-2 mb-4">
          <h4>Plans Not Available </h4>
        </div>
      </div>
    </div>
    <!-- modal notify -->
    <b-modal
        id="notifyModal"
        size="md"
        v-model="show"
    >
      <template #modal-header>
        <div class="popUp-header">
          <b-button variant="close-btn close-notify-modal"  @click="show=false"><span class="me-2">Dismiss</span>
            <!-- <i class="fas fa-times"></i> -->
            <img src="../../assets/images/x-mark.svg" alt="close">
          </b-button>
        </div>
      </template>
      <b-container class="p-0">
        <div class="modal-wrap ">
          <h5 class="">
            {{notifyMessage}}
          </h5>
          <div class="btn-cart" v-if="!errorMessage">
            <div class="d-flex justify-content-center align-items-center">
              <button class="view-cart" @click="routeToLink('/cart')"><img src="../../assets/images/empty-cart.svg" class="me-1"/>View Cart</button>
            </div>
            <div class="d-flex justify-content-center align-items-center">
              <button class="add-more-plan" @click="show = false"><i class="fas fa-plus " ></i><span class="ms-1">Add More Plans</span></button>
            </div>
          </div>

        </div>
      </b-container>
    </b-modal>
  </div>
</template>
<script>
// import { ref } from "vue";
import axios from "axios";
import BundlePlan from "./NewHomeBundlePlan.vue";
import PriceDetailInfo from "./PriceDetailInfoNewHome.vue";
import CallOutText from "../CallOutText";

export default {
  name: "NewPlans",
  components: {
    PriceDetailInfo,
    BundlePlan,
    CallOutText
  },
  mounted() {
    this.tempId = window.localStorage.getItem('tempId');
    this.newCategory =  window.localStorage.getItem("new_landing_category").toLowerCase() ? window.localStorage.getItem("new_landing_category"): ''
    this.fetchPlans();
    this.getNetworkPlanList();
  },
  data() {
    return {
      showDiv: false, // Initialize to hide the div
      showDivChild: false,
      numberValue: 0,
      spouseDOB: "",
      selectedNewPlan: [],
      selectedNewElevatePlan: [],
      error: "",
      specialError: "",
      planCategories: JSON.parse(window.localStorage.getItem("planCategories")),
      steps: 1,
      filteredPlans: null,
      selectedPlan: null,
      categoryType: window.localStorage.getItem("new_landing_category").toUpperCase(),
      tempId: '',
      isPrudentialPlan: false,
      bundleCount2: [],
      bundleCount3: [],
      bundleCount4: [],
      bundleCount5: [],
      notifyMessage: '',
      errorMessage: false,
      show:false,
      isUnderOverFlagEmit: false,
      medicalPlanNetworkList: [],
      selectedNetworkPlan: '',
      newCategory: ''
    };
  },
  methods: {
    toggleDiv() {
      // this.showDivChild = false;
      this.showDiv = !this.showDiv; // Toggle the showDiv value on click
      // this.showDivChild = !this.showDivChild;
    },
    toggleDivChild() {
      // this.showDiv = false;
      this.showDivChild = !this.showDivChild; // Toggle the showDiv value on click
    },
    increment() {
      this.numberValue++;
    },
    decrement() {
      this.numberValue <= 0 ? 0 : this.numberValue--;
    },
    routeToViewPlan (items) {
      let app = this
      app.planDetails(items)
    },
    updateTier(selectedPlan) {
      let app = this;
      let params = {
        enrollment_id: app.tempId,
        plan_id: selectedPlan,
        no_of_children: app.numberValue,
        dep_dob: app.spouseDOB,
      };
      axios
          .post(process.env.VUE_APP_API_BASE + "/update-tier-info", params)
          .then(function (response) {
            console.log(response);
            app.updateDependent(params);
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    addPlan (items) {
      let app = this
      let params = {}
      params['enrollment_id'] = app.tempId
      params['plan_id'] = items.plan_id
      app.checkPrudentialPlan(params)
    },
    updateDependent(params) {
      let app = this;
      axios
          .post(
              process.env.VUE_APP_API_BASE + "/store-temp-dependent-info",
              params
          )
          .then(function (response) {
            console.log(response);
            app.getPlanPricing(params);
            app.error = "";
          })
          .catch(function (error) {
            app.handleError(error, app);
          });
    },
    getPlanPricing(params) {
      let app = this;
      let newParam = {
        enrollment_id: params.enrollment_id,
        pid: params.plan_id,
      };
      axios.get(process.env.VUE_APP_API_BASE + "/get-plan-pricing-detail", {
        params: newParam,
      })
          .then(function (response) {
            app.specialError = response.data.message;
            app.selectedNewPlan = response.data.plans[app.checkSelectedPlanIndex(response.data.plans)];
            if (app.isPrudentialPlan) {
              app.getPlanPricingElevate(params);
            } else {
              app.addCart(app.selectedNewPlan)
            }
            app.error = "";
          })
          .catch(function (error) {
            console.log(error);
            app.handleError(error, app);
          });
    },
    checkPrudentialPlan(params) {
      let app = this;
      axios.get(process.env.VUE_APP_API_BASE+'/check-prudential-plan', {
        params: {
          enrollment_id: app.tempId,
          plan_id: params.plan_id,
        }
      })
          .then(function (response) {
            if (response.data.status == 'success') {
              app.isPrudentialPlan = response.data.data.status;
              app.getPlanPricing(params);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    checkSelectedPlanIndex (data) {
      let planIndex
      data.forEach((plan, index) => {
        if (plan.selected_plan) {
          planIndex = index
        }
      })
      return planIndex
    },
    getPlanPricingElevate(params) {
      let app = this;
      let newParam = {
        enrollment_id: params.enrollment_id,
        pid: params.plan_id,
      };
      axios.get(process.env.VUE_APP_API_BASE + "/get-plan-pricing-detail-elevate", { params: newParam })
          .then(function (response) {
            app.selectedNewElevatePlan = response.data.plans[0];
            app.addCart(app.selectedNewPlan)
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    routeToLink(link) {
      let app = this
      app.$router.push(`${link}`)
    },
    addCart: function (selectedNewPlan) {
      let app = this;
      app.errorMessage = false
      let param = {
        enrollment_id: app.tempId,
        plan_id: selectedNewPlan.pid,
        plan_pricing_id: selectedNewPlan.ppid,
      };

      let url = "/add-plan-to-cart";
      if (app.isPrudentialPlan) {
        url = "/add-plan-to-cart-prudentials";
        param = {
          enrollment_id: app.tempId,
          plan_id: app.selectedNewElevatePlan.pid,
          plan_pricing_id: app.selectedNewElevatePlan.ppid,
          plan_id1: selectedNewPlan.pid,
          plan_pricing_id1: selectedNewPlan.ppid,
        };
      }
      axios.post(process.env.VUE_APP_API_BASE + url, param)
          .then(function (response) {
            app.notifyMessage = ''
            app.fetchPlans();
            app.$root.$emit('cartData', app.tempId)
            app.notifyMessage = response.data.message
            app.show = true
          })
          .catch(function (error) {
            app.notifyMessage = ''
            app.errorMessage = true
            if (error.response.data[0].status == 'error') {
              if (error.response.data[0].errorCode == 'blackout') {
                app.notifyMessage =  error.response.data[0].message
                app.show = true
              } else if (error.response.data[0].errorCode == 'already-active') {
                app.notifyMessage =  error.response.data[0].message
                app.show = true
              } else {
                app.blackoutErr.value = false;
                app.alreadyActive.value = false;
              }
            }
          });
    },
    addAnotherPlan: function (selectedNewPlan) {
      let app = this;
      let param = {
        enrollment_id: app.tempId,
        plan_id: selectedNewPlan.pid,
        plan_pricing_id: selectedNewPlan.ppid,
      };

      let url = "/add-plan-to-cart";
      if (selectedNewPlan.is_prudential_plan) {
        url = "/add-plan-to-cart-prudentials";
        param = {
          enrollment_id: app.tempId,
          plan_id: this.selectedNewElevatePlan.pid,
          plan_pricing_id: this.selectedNewElevatePlan.ppid,
          plan_id1: selectedNewPlan.pid,
          plan_pricing_id1: selectedNewPlan.ppid,
        };
      }
      axios
          .post(process.env.VUE_APP_API_BASE + url, param)
          .then(function (response) {
            console.log(response);
            if (response.data.status == "success") {
              app.$root.$emit('cartData', app.tempId)
              app.$router.push("/");
            }
          })
          .catch(function (error) {
            console.log(error.response);
            /*if (error.response.data[0].status == 'error') {
                if (error.response.data[0].errorCode == 'blackout') {
                  app.blackoutErr.value = true;
                  app.blackoutErr.message = error.response.data[0].message;
                } else if (error.response.data[0].errorCode == 'already-active') {
                  console.log('correct');
                  app.alreadyActive = true;
                  app.alreadyActiveMessage = error.response.data[0].message;
                } else {
                  app.blackoutErr.value = false;
                  app.alreadyActive.value = false;
                }
              }*/
          });
    },
    handleError: function (error, self) {
      if (error.response.data.message) {
        self.error = error.response.data.message;
      } else if (error.response.data.status == "error") {
        error.response.data.data.forEach(function (item) {
          self.error = item.error_message;
        });
      }
    },
    fetchPlans() {
      let paramsObj = {}
      paramsObj['enrollment_id'] = this.tempId
      paramsObj['categories'] = [window.localStorage.getItem("new_landing_category")]
      if (this.categoryType.toLowerCase() === 'medical' && this.selectedNetworkPlan) {
        paramsObj['plan_network'] = this.selectedNetworkPlan
      }
      axios.get(process.env.VUE_APP_API_BASE + "/get-filtered-plans", {params: paramsObj})
          .then((response) => {
            this.filteredPlans = []
            this.bundleCount2 = [],
            this.bundleCount3 = [],
            this.bundleCount4 = [],
            this.bundleCount5 = []
            let data = response.data.data.result
            if ((this.categoryType === 'BUNDLES' || this.categoryType === 'bundles')) {
              data.forEach((item) => {
                if (item?.bundle_plan_count == 2) {
                  this.bundleCount2.push(item)
                }
                if (item?.bundle_plan_count == 3) {
                  this.bundleCount3.push(item)
                }
                if (item?.bundle_plan_count == 4) {
                  this.bundleCount4.push(item)
                }
                if (item?.bundle_plan_count == 5) {
                  this.bundleCount5.push(item)
                }
              })
            } else {
              this.filteredPlans.length < 1 ? this.checkRouteLogic(data) :   this.filteredPlans = data;
            }
          })
          .catch((error) => {
            console.log(error);
          });
    },
    checkRouteLogic (data) {
      let app = this;
      if (app.isUnderOverFlagEmit) {
        app.$router.push('/')
      } else {
        app.filteredPlans = data
        app.isUnderOverFlagEmit = false
      }
    },
    choosePlan(plan) {
      this.steps++;
      this.selectedPlan = plan;
      let params = {
        enrollment_id: this.tempId,
        plan_id: this.selectedPlan.plan_id,
        no_of_children: this.numberValue,
        dep_dob: this.spouseDOB
      }
      console.log('parmasInfo', params)
      this.getPlanPricing(params)
    },
    planDetails(id) {
      this.$router.push({
        name: "plan",
        params: { id },
      });
    },
    updateFilter(selectedCategory) {
      window.localStorage.setItem('new_landing_category', selectedCategory);
      // this.steps = 1;
      this.categoryType = selectedCategory
      this.selectedNetworkPlan = ''
      this.fetchPlans();
    },
    renderNetworkPlan () {
      let app = this
      app.fetchPlans()
    },
    getNetworkPlanList() {
      let app = this;
      let filterParams = {}
      filterParams['enrollment_id'] = app.tempId
      axios.get(process.env.VUE_APP_API_BASE + "/get-plan-network", {
        params: filterParams
      })
          .then(function (response) {
            app.medicalPlanNetworkList = [
              { value: '', text: 'Choose Medical Network' },
              ...response.data.data.map((plan) => ({ value: plan,  text: plan.toLowerCase().replace(/^\w/, c => c.toUpperCase())}))
            ];
          })
          .catch(function (error) {
            console.log(error)
          });
    },
    checkMedicalNetwork () {
      let app = this
      if (app.categoryType.toLowerCase() === 'medical') {
        return true
      } else {
        return false
      }
    },
    restNetworkFilter () {
      let app = this
      app.selectedNetworkPlan = ''
      app.renderNetworkPlan()
    }
  },
  listener: {
    getFilterPlanList () {
      this.isUnderOverFlagEmit = true
      this.fetchPlans()
    },
  }
};
</script>
<style scoped>
.bundle-heading {
  padding-bottom: 30px;
}
.network-container .custom-select {
  padding: 10px 40px 10px 8px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #ffffff;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
}
.network-container .custom-select:focus {
  outline: none !important;
  box-shadow: none !important;
}
.network-container .clear-btn {
  position: relative;
  top: 2px;
  margin-left: 8px;
  padding: 8px 12px;
  font-size: 0.875rem;
  font-weight: 500;
  border-radius: 5px;
  color: #0099cc;
  background-color: transparent;
  border: 1px solid #0099cc;
}
.network-container .clear-btn:hover {
  background-color: transparent;
  border-color: #0099cc;
  color: #0099cc;
}
</style>
