<template>
  <div></div>
</template>
<script>
import Api from "@/includes/Api";
import axios from 'axios';

export default {
  name: "direct",
  data: function () {
    return {
      tempId: '',
      routeName: ''
    }
  },
  mounted() {
    let app = this;
    localStorage.clear();
    app.tempId = atob(app.$route.params.id);
    app.directContinue(atob(this.$route.params.id), atob(this.$route.params.tab));
  },
  methods : {
    directContinue: function (id, tab) {
      let app = this;
      axios.get(process.env.VUE_APP_API_BASE+'/direct-continue/'+app.tempId)
          .then(function (response) {
            if (response.data.status == 'success') {
              window.localStorage.setItem('tempId', id);
              window.localStorage.setItem('group_id', response.data.data.result.others.group_id);
              window.localStorage.setItem('portal_id', response.data.data.result.others.platform_group_id);
              window.localStorage.setItem('agent_id', response.data.data.result.others.agent_id);
              window.localStorage.setItem('AGENT_ID', response.data.data.result.others.agent_id);
              window.localStorage.setItem('open_type', response.data.data.result.others.open_type);
              window.localStorage.setItem('eprocess', response.data.data.result.others.eprocess);
              window.localStorage.setItem('redirectionType', 'direct-continue');
              app.enrollmentId = id;
              app.savedTab = tab
              if (response.data.data.result.others.open_type === 'group') {
                app.$store.dispatch("isEmployeeEnrollment", true);
              } else {
                app.$store.dispatch("isEmployeeEnrollment", false);
              }

              if (response.data.data.result.others.type == 'new-user') {
                window.localStorage.setItem('enroll_type', 'new-member');
              } else {
                window.localStorage.setItem('enroll_type', 'existing-member');
              }
              window.localStorage.setItem('continue_track', 'yes');
              app.routeName = app.$route.name
              app.getNavTabs();
            }

          })
          .catch(function (error) {
            console.log(error);
          });
    },
    openTab: async function (tempId, tab) {
      this.$root.$emit('cartData', tempId);
      if (tab == 'primary_info') {
        window.localStorage.setItem('redirectionType', 'group-census-direct-continue');
        this.$router.push('/enrollment/personal-info');
      } else if (tab == 'contact_info') {
        this.$router.push('/enrollment/contact-info');
      } else if (tab == 'employer_info') {
        this.$router.push('/enrollment/employer-info');
      } else if (tab == 'benificiary_info') {
        this.$router.push('/enrollment/beneficiary-info');
      } else if (tab == 'health_info') {
        this.$router.push('/enrollment/health-questions');
      } else if (tab == 'dependent_info') {
        this.$router.push('/enrollment/dependent-information');
      } else if (tab == 'review_info') {
        this.$router.push('/enrollment/review-confirm');
      } else if (tab == 'aggrement_info') {
        this.$router.push('/enrollment/agreement-signature');
      } else if (tab == 'payment_info') {
        this.$router.push('/enrollment/billing-information');
      } else if (tab == 'cart_info') {
        window.localStorage.setItem('redirectionType', 'save-cart');
        window.localStorage.setItem('show-quotation', 'no');
        this.checkRedirectionType()
        await this.renderTierInformation(tempId);
        this.$router.push('/cart');
      } else {
        this.$router.push('/404')
      }
      window.location.reload();
    },
    checkRedirectionType () {
      if ((this.routeName == 'group-census-direct-continue') || (this.routeName == 'pqs-direct-continue')) {
        window.localStorage.setItem('routeRedirection',  this.routeName);
        window.localStorage.setItem('platformType', 'rep-quote');
      }
    },
    getNavTabs: function() {
      let nav_array = [];
      let tempId = window.localStorage.getItem('tempId');
      // let tempId = this.$route.params.id;
      //Lets pull Nav Data from API
      Api.get('/get-dynamic-tabs?enrollment_id='+ tempId).then((response) => {
        let index = 1;
        let order = 0;
        for (order = 0; order <= 10; order++) {
          response.data.data.result.forEach((nav) => {
            if(nav.order == order){
              if(nav.tab_name == 'primary_info' && nav.required){
                nav_array.push({"index": index, "title": 'Personal Information', "name": 'personal-information', "visible": true});
                index++;
              }
              if(nav.tab_name == 'contact_info' && nav.required){
                nav_array.push( {"index": index, "title": 'Contact Information', "name": 'contact-information', "visible": true});
                index++;
              }
              if(nav.tab_name == 'employer_info' && nav.required){
                nav_array.push({"index": index, "title": 'Employer Information', "name": 'employer-information', "visible": true});
                index++;
              }
              if(nav.tab_name == 'beneficiary_info' && nav.required){
                nav_array.push({"index": index, "title": 'Beneficiary Information', "name": 'beneficiary-information', "visible": true});
                index++;
              }
              if(nav.tab_name == 'primary_health_info' && nav.required){
                nav_array.push({"index": index, "title": 'Health Questions', "name": 'health-questions', "visible": true});
                index++;
              }
              if(nav.tab_name == 'dependent_info' && nav.required){
                nav_array.push({"index": index, "title": 'Dependent Information', "name": 'dependent-information', "visible": true});
                index++;
              }
              if(nav.tab_name == 'review_info' && nav.required){
                nav_array.push({"index": index, "title": 'Review & Confirmation', "name": 'review-confirm', "visible": true});
                index++;
              }
              if(nav.tab_name == 'agreement_info' && nav.required){
                nav_array.push({"index": index, "title": 'Agreement & Signature', "name": 'agreement-signature', "visible": true});
                index++;
              }
              if(nav.tab_name == 'billing_info' && nav.required){
                nav_array.push({"index": index, "title": 'Billing Information', "name": 'billing-information', "visible": true});
                index++;
              }
            }
          });
        }
        window.localStorage.setItem("nav_array", JSON.stringify(nav_array));
        this.openTab(this.enrollmentId, this.savedTab);
      }).catch(() => {

      }).then(() => {

      });

    },
    renderTierInformation: async function (tempId) {
      if (tempId) {
        try {
          const response = await axios.get(process.env.VUE_APP_API_BASE + '/get-enrollment-temp-info', {
            params: {
              enrollment_id: tempId,
              column: 'filter_info'
            }
          });
          if (response.data.status == 'success') {
            let tier = response.data.data.result.tier;
            window.localStorage.setItem('selectedTier', tier);
          }
        } catch (error) {
          console.log(error);
        }
      }
    }
  }
}
</script>